// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { ThemeId } from '../fs-next/theming/themes';
import { EMode, Mode } from './assessment.model';

// TODO: Should these be pipes? -Andy
//
// Yes probably — and also we should use something like Record<EMode, string>
// for the mappings to ensure all the modes are represented. -Sandy
export class AssessmentDefinitions {
  readonly MODE = Mode;
  readonly MODES = Object.keys(Mode);

  // TODO: This should be moved to css
  static getModeColour(mode: keyof typeof Mode, opacity = 1): string {
    switch (Mode[mode]) {
      case Mode.SLAVES: {
        return `rgba(132,0,147,${opacity})`; //840093
      }
      case Mode.GHG_EMISSIONS: {
        return `rgba(39,211,168,${opacity})`; //27D3A8
      }
      case Mode.BIODIVERSITY: {
        return `rgba(255,82,88,${opacity})`; //FF5258
      }
      case Mode.LAND: {
        return `rgba(246,217,71,${opacity})`; //F6D947
      }
      case Mode.WATER: {
        return `rgba(56,174,247,${opacity})`; //38AEF7
      }
      case Mode.TIVA: {
        return `rgba(255,170,195,${opacity})`; //FFAAC3
      }
      default: {
        return '';
      }
    }
  }

  static getModeText(mode: keyof typeof Mode): string {
    switch (Mode[mode]) {
      case Mode.SLAVES: {
        return 'forced labour';
      }
      case Mode.GHG_EMISSIONS: {
        return 'emissions';
      }
      case Mode.LAND: {
        return 'land use';
      }
      case Mode.WATER: {
        return 'water use';
      }
      case Mode.TIVA: {
        return 'trade in value-added';
      }
      case Mode.BIODIVERSITY: {
        return 'extinction-risk';
      }
      default: {
        return '';
      }
    }
  }

  static getModeText2(mode: keyof typeof Mode, intensity = false): string {
    let text = '';

    switch (Mode[mode]) {
      case Mode.SLAVES: {
        text = 'estimated people in forced labour';
        break;
      }
      case Mode.GHG_EMISSIONS: {
        text = 'amount of carbon emissions';
        break;
      }
      case Mode.LAND: {
        text = 'amount of land used';
        break;
      }
      case Mode.WATER: {
        text = 'amount of water used';
        break;
      }
      case Mode.TIVA: {
        text = 'value generated';
        break;
      }
      case Mode.BIODIVERSITY: {
        text = 'extinction-risk footprint';
        break;
      }
    }

    return text + (intensity ? ' per $M' : '');
  }

  static getModeText3(mode: keyof typeof Mode, intensity = false): string {
    let text = '';

    switch (Mode[mode]) {
      case Mode.SLAVES: {
        text = 'estimated number of people in forced labour';
        break;
      }
      case Mode.GHG_EMISSIONS: {
        text = 'amount of carbon emissions';
        break;
      }
      case Mode.LAND: {
        text = 'amount of land used';
        break;
      }
      case Mode.WATER: {
        text = 'amount of water used';
        break;
      }
      case Mode.TIVA: {
        text = 'value generated';
        break;
      }
      case Mode.BIODIVERSITY: {
        text = 'extinction risk';
        break;
      }
    }

    return text + (intensity ? ' per $M' : '');
  }

  static getModeText4(mode: keyof typeof Mode): string {
    switch (Mode[mode]) {
      case Mode.SLAVES: {
        return 'modern slavery';
      }
      case Mode.GHG_EMISSIONS: {
        return 'carbon emissions';
      }
      case Mode.LAND: {
        return 'land use';
      }
      case Mode.WATER: {
        return 'water use';
      }
      case Mode.TIVA: {
        return 'trade in value-added';
      }
      case Mode.BIODIVERSITY: {
        return 'biodiversity loss';
      }
      default: {
        return '';
      }
    }
  }

  static getModeText5(mode: keyof typeof Mode, intensity = false): string {
    let text = '';

    switch (Mode[mode]) {
      case Mode.SLAVES: {
        text = 'total estimated number of people in forced labour';
        break;
      }
      case Mode.GHG_EMISSIONS: {
        text = 'total amount of CO₂-e in kilotonnes';
        break;
      }
      case Mode.LAND: {
        text = 'total amount of land used in hectares';
        break;
      }
      case Mode.WATER: {
        text = 'total amount of water used in megalitres';
        break;
      }
      case Mode.TIVA: {
        text = 'value generated';
        break;
      }
      case Mode.BIODIVERSITY: {
        text = 'total extinction-risk footprint';
        break;
      }
    }

    return text + (intensity ? ' per $M' : '');
  }

  static getModeUnitText(mode: keyof typeof Mode, intensity = false): string {
    const units = modes[mode]?.units;
    const text = units?.text ?? 'footprint';
    return `${text}${intensity ? ' per $M' : ''}`;
  }

  static getSimpleModeUnitText(mode: keyof typeof Mode, intensity = false): string {
    const units = modes[mode]?.units;
    const text = units?.simpleText ?? 'footprint';
    return `${text}${intensity ? ' per $M' : ''}`;
  }

  static getSimpleModeUnitsAreCaseSensitive(mode: keyof typeof Mode, intensity = false): boolean {
    const units = modes[mode]?.units;
    return units?.caseSensitive ?? false;
  }

  static getSimpleModeDescription(mode: keyof typeof Mode, riskType: 'footprint' | 'intensity') {
    let text: string;
    switch (Mode[mode]) {
      case Mode.SLAVES: {
        text = 'number of people in forced labour';
        break;
      }
      case Mode.GHG_EMISSIONS: {
        text = 'carbon emissions';
        break;
      }
      case Mode.BIODIVERSITY: {
        text = 'species extinction-risk';
        break;
      }
      case Mode.LAND: {
        text = 'amount of land used';
        break;
      }
      case Mode.WATER: {
        text = 'amount of water used';
        break;
      }
      case Mode.TIVA: {
        text = 'value generated';
        break;
      }
    }
    const riskTypeText = riskType === 'footprint' ? 'based on your expenditure' : 'per million dollars spent';

    return `The calculated ${text} ${riskTypeText}`;
  }

  static getSimpleModeText(mode: keyof typeof Mode): string {
    switch (Mode[mode]) {
      case Mode.SLAVES: {
        return 'modern slavery';
      }
      case Mode.GHG_EMISSIONS: {
        return 'emissions';
      }
      case Mode.LAND: {
        return 'land use';
      }
      case Mode.WATER: {
        return 'water use';
      }
      case Mode.TIVA: {
        return 'trade in value-added';
      }
      case Mode.BIODIVERSITY: {
        return 'biodiversity';
      }
    }
  }

  static getModeNumIndicators(mode: string): string {
    switch (Mode[mode]) {
      case Mode.SLAVES: {
        return '8';
      }
      case Mode.GHG_EMISSIONS: {
        return '4';
      }
      case Mode.LAND: {
        return '4';
      }
      case Mode.WATER: {
        return '4';
      }
      case Mode.TIVA: {
        return '4';
      }
      case Mode.BIODIVERSITY: {
        return '4';
      }
    }

    return '';
  }

  static getRiskTypeTitle(riskType: 'footprint' | 'intensity') {
    switch (riskType) {
      case 'footprint': {
        return 'Impact';
      }
      case 'intensity': {
        return 'Exposure';
      }
    }
  }

  static getIndustryRiskTooltip(mode: keyof typeof Mode): string {
    switch (Mode[mode]) {
      case Mode.GHG_EMISSIONS: {
        return 'A rating that assesses the relative contribution of a company and its supply chains to global CO₂-e emissions.';
      }
      case Mode.BIODIVERSITY: {
        return 'A rating that assesses the relative contribution of a company and its supply chains to global extinction risk.';
      }
    }

    // TODO: We may need to review this for the other modes (water, land use, TIVA). They each may need a specific text for correct meaning/grammar.
    return (
      'A rating that assesses the severity of the risk of ' +
      this.getModeText4(mode) +
      ' in a company and its supply chains.'
    );
  }
}

type ModeDetails = {
  units: {
    // TODO: Should these be swapped? It seems better to have (simple) text as the default and longText as the exception. - SF
    simpleText: string;
    text: string;
    caseSensitive: boolean;
  };
  display: {
    title: string;
  };
  palette: ThemeId;
};

export const modes: Record<EMode, Readonly<ModeDetails>> = {
  [EMode.SLAVES]: {
    units: {
      simpleText: 'people',
      text: 'estimated people in forced labour',
      caseSensitive: false,
    },
    display: {
      title: 'Modern Slavery',
    },
    palette: 'orchid',
  },
  [EMode.GHG_EMISSIONS]: {
    units: {
      simpleText: 'kt CO₂-e',
      text: 'kt CO₂-e',
      caseSensitive: true,
    },
    display: {
      title: 'GHG Emissions',
    },
    palette: 'green-tea',
  },
  [EMode.LAND]: {
    units: {
      simpleText: 'ha',
      text: 'ha',
      caseSensitive: true,
    },
    display: {
      title: 'Land Use',
    },
    palette: 'gray', // TODO: replace with actual palette
  },
  [EMode.WATER]: {
    units: {
      simpleText: 'ML',
      text: 'ML',
      caseSensitive: true,
    },
    display: {
      title: 'Water',
    },
    palette: 'gray', // TODO: replace with actual palette
  },
  [EMode.TIVA]: {
    units: {
      simpleText: 'dollars of value generated',
      text: 'dollars of value generated',
      caseSensitive: false,
    },
    display: {
      title: 'Trade in Value Added',
    },
    palette: 'gray', // TODO: replace with actual palette
  },
  [EMode.BIODIVERSITY]: {
    units: {
      simpleText: 'nSTAR',
      text: 'nSTAR',
      caseSensitive: true,
    },
    display: {
      title: 'Biodiversity',
    },
    palette: 'biodiversity-blue',
  },
};
